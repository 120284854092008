import * as runtypes from 'runtypes';

import {
  PrismicFieldEmbed,
  PrismicFieldEmbedSchema,
  PrismicFieldImageSchema,
  PrismicFieldRelationshipSchema,
  PrismicItemRichText,
  PrismicItemRichTextSchema,
  PrismicItemTitleSchema,
} from './prismic-fields';

//
export const ExperienceDocDataSchema = runtypes.Record({
  // main
  title: runtypes.Array(PrismicItemTitleSchema),
  name: runtypes.Array(PrismicItemTitleSchema),
  slug: runtypes.Array(PrismicItemTitleSchema),
  category1: PrismicFieldRelationshipSchema,
  category2: PrismicFieldRelationshipSchema,
  category3: PrismicFieldRelationshipSchema,
  category4: PrismicFieldRelationshipSchema,
  category5: PrismicFieldRelationshipSchema,

  subcategories: runtypes.Array(
    runtypes.Record({
      subcategory: PrismicFieldRelationshipSchema,
    }),
  ),

  description: runtypes.Array(PrismicItemRichTextSchema),

  // main photo
  main_photo: runtypes.Intersect(
    PrismicFieldImageSchema,
    runtypes.Record({
      portrait: PrismicFieldImageSchema,
      landscape: PrismicFieldImageSchema,
    }),
  ),

  main_photo_attribution: runtypes.Array(PrismicItemRichTextSchema),

  // media
  media_videos: runtypes.Array(
    runtypes.Record({
      media_video: PrismicFieldEmbedSchema
        .Or(runtypes.Record({})), // prismic sends empty object instead of null or undefined
    }),
  ),

  body: runtypes.Array(
    runtypes.Record({
      slice_type: runtypes.Literal('galeria_de_fotos'), // ughhhhhh
      items: runtypes.Array(
        runtypes.Record({
          gallery_photo: PrismicFieldImageSchema,
          gallery_photo_description: runtypes.Array(PrismicItemRichTextSchema),
          gallery_photo_attribution: runtypes.Array(PrismicItemRichTextSchema),
        }),
      ),
      primary: runtypes.Record({
        gallery_title: runtypes.Array(PrismicItemTitleSchema),
        gallery_attribution: runtypes.Array(PrismicItemRichTextSchema),
      }),
    }),
  ),

  // info
  info_kid_friendly: runtypes.Boolean,
  info_pet_friendly: runtypes.Boolean,
  info_website: runtypes.Array(PrismicItemTitleSchema),
  info_links: runtypes.Array(
    runtypes.Record({
      info_link: runtypes.Array(PrismicItemTitleSchema),
    }),
  ),
  location_country: runtypes.Array(PrismicItemTitleSchema),
  location_state: runtypes.Array(PrismicItemTitleSchema),
  location_city: runtypes.Array(PrismicItemTitleSchema),
  location_address: runtypes.Array(PrismicItemTitleSchema),

  // price
  price_currency: runtypes.String,
  price_average: runtypes.Number.Or(runtypes.Null),
  price_min: runtypes.Number.Or(runtypes.Null),
  price_max: runtypes.Number.Or(runtypes.Null),
  price_unit: runtypes.Array(PrismicItemTitleSchema),
  price_info: runtypes.Array(PrismicItemRichTextSchema),

  // accomodation details
  accomodation_type: runtypes.String.Or(runtypes.Null),

  // restaurant details
  restaurant_vegetarian_friendly: runtypes.Boolean,
  restaurant_vegan_friendly: runtypes.Boolean,
});

//
export interface ExperienceDoc {
  id: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  name: string;
  slug: string;
  categories: Array<{
    path: string;
    name: string;
    subcategories: Array<{
      path: string;
      name: string;
      title?: string;
    }>;
  }>;
  description: PrismicItemRichText[];

  mainPhoto: {
    url: string;
    width: number;
    height: number;

    landscape: {
      url: string;
      width: number;
      height: number;
      cropRect: string | undefined;
    };

    portrait: {
      url: string;
      width: number;
      height: number;
      cropRect: string | undefined;
    };
  };

  galleries: {
    title?: string;
    attribution?: PrismicItemRichText[];

    items: {
      url: string;
      width: number;
      height: number;
      description?: PrismicItemRichText[];
      attribution?: PrismicItemRichText[];
    }[];
  }[];

  videos: PrismicFieldEmbed[];

  links: {
    main?: [string, string];
    secondary?: [string, string][];
  };

  location: {
    country?: string;
    state?: string;
    city?: string;
    address?: string;
  };

  pricing: {
    currency: string;
    min?: number;
    max?: number;
    average?: number;
    unit?: string;
    info?: PrismicItemRichText[];
  };

  petFriendly: boolean;
  kidFriendly: boolean;
}
