import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import * as runtypes from 'runtypes';

import { ExperienceDocDataSchema } from '../../../config/prismic/runtypes/experience-document';
import ExperienceList from '../../components/ExperienceList';
import Layout from '../../components/Layout';
import Spinner from '../../components/Spinner';
import { prismicApiRestEndpoint } from '../../config/prismic-api';
import { ExperiencePreview } from '../../types/experience';
import { usePrismicMasterRef } from '../../util/use-prismic-master-ref';

import './Search.scss';

//
const APISearchResponseSchema = runtypes.Record({
  results: runtypes.Array(
    runtypes.Record({
      data: ExperienceDocDataSchema,
    }),
  ),
});

type APISearchResults = runtypes.Static<typeof APISearchResponseSchema>['results'];

const prismicApiRestSearchEndpoint = (ref: string, query: string): string => (
  prismicApiRestEndpoint + `/documents/search?ref=${ref}&q=`
  + `[[at(document.type,"experience")]]&q=[[fulltext(document,"${query}")]]`
);

// search state screen
const SearchHeading: React.FC<{ query: string }> = ({ query }) => (
  <h2 className="search-heading">Busca por &quot;<span>{query}</span>&quot;</h2>
);

const SearchDefault: React.FC = () => (
  <div className="search-empty">
    <h2>Busca</h2>
  </div>
);

const SearchError: React.FC = () => (
  <div className="search-error">
    <h3>Ops, algo deu errado!</h3>

    <p>
      <span role="img" aria-label="Emoji">😵</span> Fizemos alguma besteira e algo não deu certo fazendo essa busca.
      {' '}Você pode tentar atualizar a página?
    </p>
  </div>
);

const SearchLoading: React.FC<{ query: string }> = ({ query }) => (
  <div className="search-loading">
    <SearchHeading query={query} />
    <Spinner label="Buscando..." />
  </div>
);

const SearchEmpty: React.FC<{ query: string }> = ({ query }) => (
  <div className="search-empty">
    <SearchHeading query={query} />

    <p>
      <span role="img" aria-label="Emoji">😢</span> Nenhuma experiência encontrada
    </p>
  </div>
);

const SearchResults: React.FC<{ query: string; results: APISearchResults }> = ({ query, results }) => {
  const experiences: ExperiencePreview[] = [];

  results.forEach((result) => {
    experiences.push({
      title: result.data.title[0].text,
      name: result.data.name[0].text,
      path: '/' + result.data.slug[0].text,
      mainPhoto: {
        landscape: {
          url: result.data.main_photo.portrait.url,
        },
      },
    });
  });

  return (
    <div className="search-results">
      <SearchHeading query={query} />
      <h5>{results.length} {results.length > 1 ? 'experiências encontradas' : 'experiência encontrada'}</h5>

      <ExperienceList experiences={experiences} />
    </div>
  );
};

//
const Search: React.FC = () => {
  // get master ref
  const masterRef = usePrismicMasterRef();

  const [searchState, setSearchState] = useState<{ results: APISearchResults } | 'loading' | 'error'>();

  // parse query string
  let query: string | undefined;
  if (typeof window !== 'undefined') {
    const { q } = qs.parse(window.location.search);
    if (typeof q === 'string' && q.trim() !== '') query = q;
  }

  // search
  useEffect(() => {
    if (masterRef === undefined || !query) return;

    if (masterRef === false) {
      setSearchState('error');
      return;
    }

    setSearchState('loading');

    (async (): Promise<void> => {
      try {
        const response = await fetch(prismicApiRestSearchEndpoint(masterRef, query));
        const json = await response.json();

        if (APISearchResponseSchema.guard(json)) {
          setSearchState({ results: json.results });
        } else {
          setSearchState('error');
        }
      } catch {
        setSearchState('error');
      }
    })();
  }, [masterRef, query]);

  // render
  let render: React.ReactNode = null;

  if (!query) render = <SearchDefault />;
  else if (!searchState || searchState === 'loading') render = <SearchLoading query={query} />;
  else if (searchState === 'error') render = <SearchError />;
  else if (searchState.results.length === 0) render = <SearchEmpty query={query} />;
  else render = <SearchResults query={query} results={searchState.results} />;

  return (
    <Layout mainClassName="search">
      <div className="container">
        {render}
      </div>
    </Layout>
  );
};

export default Search;
