import * as runtypes from 'runtypes';

// item: title
export const PrismicItemTitleSchema = runtypes.Record({
  type: runtypes.Literal('heading1'),
  text: runtypes.String,
});

export type PrismicItemTitle = runtypes.Static<typeof PrismicItemTitleSchema>;

// item: rich text
export const PrismicItemRichTextSchema = runtypes.Record({
  type: runtypes.String,
  text: runtypes.String,
  spans: runtypes.Array(
    runtypes.Record({
      start: runtypes.Number,
      end: runtypes.Number,
      type: runtypes.String,
      text: runtypes.String.Or(runtypes.Undefined),
    }),
  ),
});

export type PrismicItemRichText = runtypes.Static<typeof PrismicItemRichTextSchema>;

// field: image
export const PrismicFieldImageSchema = runtypes.Record({
  dimensions: runtypes.Record({
    width: runtypes.Number,
    height: runtypes.Number,
  }),

  url: runtypes.String,
});

export type PrismicFieldImage = runtypes.Static<typeof PrismicFieldImageSchema>;

// field: relationship
export const PrismicFieldRelationshipSchema = runtypes.Record({
  link_type: runtypes.Literal('Document'),
}).And(
  runtypes.Partial({
    type: runtypes.String,
    id: runtypes.String,
  }),
);

export type PrismicFieldRelationship = runtypes.Static<typeof PrismicFieldImageSchema>;

// field: embed
export const PrismicFieldEmbedSchema = runtypes.Record({
  provider_name: runtypes.String,
}).And(
  runtypes.Partial({
    html: runtypes.String,
  }),
);

export type PrismicFieldEmbed = runtypes.Static<typeof PrismicFieldEmbedSchema>;
