import { Link } from 'gatsby';
import React from 'react';

import { ExperiencePreview } from '../../types/experience';
import { buildImgixUrlWithAspectRatio } from '../../util/images';
import AspectBox from '../AspectBox';
import Img from '../Img';

import './ExperienceList.scss';

const mainPhotoAspectRatio = 1 / 0.7;
const mainPhotoMaxWidth = 600;

const ExperienceList: React.FC<{
  experiences: ExperiencePreview[];
  variant?: 'default' | 'light';
}> = ({ experiences, variant = 'default' }) => (
  <div className={variant === 'default' ? 'experience-list' : `experience-list experience-list-${variant}`}>
    {experiences.map((experience) => (
      <div key={experience.path} className="experience">
        <Link to={experience.path}>
          <AspectBox heightRatio={mainPhotoAspectRatio}>
            <Img
              src={buildImgixUrlWithAspectRatio(experience.mainPhoto.landscape.url, mainPhotoAspectRatio, mainPhotoMaxWidth)}
            />
          </AspectBox>

          <h2>
            {experience.title}
          </h2>
        </Link>
      </div>
    ))}
  </div>
);

export default ExperienceList;
